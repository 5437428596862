import {
  IconDefinition,
  faArrowUpRightDots,
  faCircleCheck,
  faJoystick,
  faRocket,
  faTally,
  faThoughtBubble,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UpgradeAPIModel, UpgradeTitle } from '@temptations-ai/types';
import { SubscriptionProvider } from '@temptations-ai/types/modules/Subscription';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  IconSize,
  IconSrc,
  getIconUrl,
} from 'src/components/Shared/Icon/Icon';
import { SheenBlock } from 'src/components/Shared/SheenBlock';
import { TemptationsButton } from 'src/components/Shared/TemptationsButton';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import { SubscriptionLevel } from 'src/services/API/ResourcesAPI';
import { nutakuCreatePaymentOptions } from 'src/services/Nutaku/Nutaku';
import { cn } from 'src/shared/utils';
import { useNutakuContext } from '../../../context/Nutaku.Context';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from '../../Shared/HornyButton/HornyButton';
import { useNutakuPayment } from '../NutakuPayment';
import { discountCount, tutorialHrefs } from '../Subscription';
// import { BoostyProviderFlow } from '../SubscriptionProviderFlow/BoostyProviderFlow';

import { G2AProviderFlow } from '../SubscriptionProviderFlow/G2AProviderFlow';
import { PriceButtonFlow } from '../SubscriptionProviderFlow/PriceButtonFlow';
// import { PatreonProviderFlow } from '../SubscriptionProviderFlow/PatreonProviderFlow';
// import { SubscribeStarProviderFlow } from '../SubscriptionProviderFlow/SubscribeStarProviderFlow';

export type UpgradeProps = {
  upgrade: UpgradeAPIModel;
  isYearly: boolean;
  upgrades: UpgradeAPIModel[];
};

export const UpgradeIcons: Record<UpgradeTitle, IconDefinition> = {
  GeneralUp: faTally,
  MemoryUp: faThoughtBubble,
  ControlUp: faJoystick,
  AdvancedUp: faArrowUpRightDots,
  SuperAI: faRocket,
};

export const UpgradeNutakuImages: Record<UpgradeTitle, IconSrc> = {
  GeneralUp: IconSrc.GeneralUp,
  MemoryUp: IconSrc.MemoryUp,
  ControlUp: IconSrc.ControlUp,
  AdvancedUp: IconSrc.AdvancedUp,
  SuperAI: IconSrc.SuperAI,
};

export function Upgrade(props: UpgradeProps) {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { prices } = useResourcesContext();
  const { isNutaku } = useNutakuContext();
  const hasUpgrade = (upgrade: UpgradeTitle): boolean => {
    return user?.Upgrades.find((u) => u.Title === upgrade) ? true : false;
  };
  const hasThisUpgrade = hasUpgrade(props.upgrade.title);
  // console.log('UPGRADE: ', props.upgrade.title, hasThisUpgrade);

  // PAYMENT
  const nutakuPaymentOptions: nutakuCreatePaymentOptions = {
    itemName: props.upgrade.title,
    itemId: props.isYearly
      ? props.upgrade.nutaku_id + '_Year'
      : props.upgrade.nutaku_id,
    itemPrice: props.isYearly
      ? props.upgrade.nutaku_price_year.toString()
      : props.upgrade.nutaku_price.toString(),
    itemImage: getIconUrl(UpgradeNutakuImages[props.upgrade.title]),
    itemDescription: props.upgrade.description,
  };
  const { nutakuCreatePayment } = useNutakuPayment({
    NutakuPayment: nutakuPaymentOptions,
    Type: 'subscription',
  });
  // /PAYMENT
  const [subscriptionProvider, setSubscriptionProvider] =
    useState<SubscriptionProvider | null>(null);
  const [priceButtonClicked, setPriceButtonClicked] = useState(false);

  useEffect(() => {
    setSubscriptionProvider(null);
    setPriceButtonClicked(false);
  }, [props.isYearly]);
  const subscriptionProvidersPreview = (
    <>
      {/* <HornyButton
        onClick={() => setSubscriptionProvider(SubscriptionProvider.Patreon)}
        bg={HornyButtonBG.Red}
        className="w-max-content"
        height={HornyButtonHeight.Shorter}
      >
        <Icon src={IconSrc.Patreon} size={IconSize.Small} />
        {t('Pay with Patreon')}
  </HornyButton> */}

      {/* {!props.isYearly && <HornyButton
        onClick={() => setSubscriptionProvider(SubscriptionProvider.Boosty)}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Shorter}
        className="w-max-content"
      >
        <Icon src={IconSrc.Boosty} size={IconSize.Small} />
        {t('Pay with Boosty')}
      </HornyButton> } */}

      <HornyButton
        onClick={() => setSubscriptionProvider(SubscriptionProvider.G2A)}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Shorter}
        className="w-max-content"
      >
        <Icon src={IconSrc.G2A} size={IconSize.Small} />
        {t('Pay with G2A')}
      </HornyButton>

      <HornyButton
        onClick={() => setPriceButtonClicked(false)}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Shorter}
        className="w-max-content"
      >
        <Icon src={IconSrc.Back} size={IconSize.Small} />
        {t('Back')}
      </HornyButton>

      {/* <HornyButton
       {/* <HornyButton
         onClick={() => setSubscriptionProvider(SubscriptionProvider.Card)}
        bg={HornyButtonBG.Red}
        className="w-max-content"
        height={HornyButtonHeight.Shorter}
      >
        <Icon src={IconSrc.Card} size={IconSize.Small} />
        {t('Pay with Card')}
</HornyButton> */}
    </>
  );
  const nonGeneralUpgradesPrice = props.upgrades.reduce(
    (acc: number, cur) =>
      cur.title !== 'GeneralUp' ? acc + Number(cur.nutaku_price) : acc,
    0
  );

  const nonGeneralUpgradesPriceYear = props.upgrades.reduce(
    (acc: number, cur) =>
      cur.title !== 'GeneralUp' ? acc + Number(cur.nutaku_price_year) : acc,
    0
  );

  // Если миксин -- не показываем кнопку
  // Если даунгрейд -- не показываем кнопку
  // Елси расширяешь меньше чем на 3 дня -- не показываем кнопку

  const isMixin = user?.Upgrades.find(
    (u) => u.Title === props.upgrade.title
  )?.IsMixin;

  const isDowngrade =
    !props.isYearly &&
    user?.Upgrades.find((u) => u.Title === props.upgrade.title)?.IsYearly;

  const newUpgradeDays = props.isYearly ? 365 : 30;
  const userUpgradeEndDate = user?.Upgrades.find(
    (u) => u.Title === props.upgrade.title
  )?.EndDate;

  const upgradeDaysLeft = userUpgradeEndDate
    ? Math.ceil(
        (new Date(userUpgradeEndDate).getTime() - Date.now()) /
          (1000 * 60 * 60 * 24)
      )
    : null;

  const showMixin =
    props.upgrade.title === 'GeneralUp' &&
    user?.MergedSubscription.Level !== SubscriptionLevel.Master;

  const canBuy =
    isDowngrade || (isMixin && !showMixin)
      ? false
      : !upgradeDaysLeft
        ? true
        : newUpgradeDays - upgradeDaysLeft > 3;

  const originalUpgrade = prices?.Upgrades.find(
    (u) => u.title === props.upgrade.title
  );

  const originalPrice = props.isYearly
    ? Number(
        isNutaku ? originalUpgrade?.nutaku_price : originalUpgrade?.price
      ) * 12
    : Number(isNutaku ? originalUpgrade?.nutaku_price : originalUpgrade?.price);

  const currentPrice = props.isYearly
    ? Number(
        isNutaku ? props.upgrade.nutaku_price_year : props.upgrade.price_year
      )
    : Number(isNutaku ? props.upgrade.nutaku_price : props.upgrade.price);

  const possibleDiscount = discountCount(currentPrice, originalPrice);

  const discount: number | undefined =
    possibleDiscount < 5 ? undefined : possibleDiscount;
  const selecteProviderIntegration = {
    [SubscriptionProvider.Card]: () => null,
    [SubscriptionProvider.Patreon]: () => null,
    [SubscriptionProvider.SubscribeStar]: () => null,
    [SubscriptionProvider.Boosty]: () => null,
    [SubscriptionProvider.G2A]: () => (
      <G2AProviderFlow
        subscriptionId={
          props.isYearly ? props.upgrade.g2a_id_year : props.upgrade.g2a_id
        }
        subscriptionLevel={props.upgrade.title}
        selectedProvider={true}
        isYearly={props.isYearly}
      />
    ),
  };

  const subscriptionProviderElement = (
    el: JSX.Element,
    provider: SubscriptionProvider
  ) => {
    console.log({
      tutorialHrefs,
      provider,
    });
    return (
      <>
        {/* <div className="d-flex flex-column gap-3 align-items-center w-100"> */}
        {el}
        {/* <div className="d-flex  gap-3 align-items-center w-100"> </div>
          <div className="d-flex  gap-3 align-items-center w-100"> */}
        <HornyButton
          onClick={() => setSubscriptionProvider(null)}
          bg={HornyButtonBG.Red}
          height={HornyButtonHeight.Shorter}
          className="w-max-content"
        >
          <Icon src={IconSrc.Back} size={IconSize.Small} />
          {t('Back to list')}
        </HornyButton>
        <a href={tutorialHrefs[provider]} target="_blank">
          <Icon className="ms-2" src={IconSrc.Info} />
        </a>
        {/* </div>
        </div> */}
      </>
    );
  };

  const subscriptionProviderInteraction = {
    [SubscriptionProvider.Card]: () => <></>,
    [SubscriptionProvider.Patreon]: () => <></>,
    [SubscriptionProvider.SubscribeStar]: () => <></>,
    [SubscriptionProvider.Boosty]: () => <></>,
    [SubscriptionProvider.G2A]: () => (
      <G2AProviderFlow
        subscriptionLevel={props.upgrade.title}
        selectedProvider={false}
        isYearly={props.isYearly}
        subscriptionId={
          props.isYearly ? props.upgrade.g2a_id_year : props.upgrade.g2a_id
        }
      />
    ),
  };
  return (
    <div
      className={cn(
        'tw-w-full tw-bg-gradient-to-b tw-from-horny-orange-400 tw-to-horny-orange-500 tw-py-6 tw-px-6 tw-rounded-xl  tw-shadow-bezel tw-relative tw-overflow-hidden',
        hasThisUpgrade
          ? 'tw-border tw-border-horny-orange-200'
          : 'tw-border-[0.5px] tw-border-horny-orange-600'
      )}
    >
      {discount && canBuy ? (
        <div className="tw-flex tw-items-center tw-rounded-bl-xl tw-px-4 tw-py-2 tw-bg-horny-orange-700 tw-bg-opacity-25 tw-font-bold tw-text-xs tw-text-nowrap tw-w-fit tw-absolute tw-top-0 tw-right-0 tw-leading-none tw-capitalize">
          {discount}
          {t('% discount')}
        </div>
      ) : null}
      {!hasThisUpgrade && <SheenBlock />}
      {hasThisUpgrade && (
        <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full tw-bg-horny-orange-600 tw-bg-opacity-30 tw-items-center tw-justify-center tw-rounded-md tw-my-3 tw-py-3">
          <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-text-3xl tw-font-bold">
            <FontAwesomeIcon icon={faCircleCheck} />
            <div>{t('Active')}</div>
          </div>
          <div>
            <span className="tw-font-bold">{t('Ends')}: </span>
            <span>
              {
                user?.Upgrades.find((u) => u.Title === props.upgrade.title)
                  ?.EndDate?.toString()
                  .split('T')[0]
              }
            </span>
          </div>
        </div>
      )}
      <div
        className={cn(
          'tw-w-full tw-flex tw-flex-col tw-gap-3 tw-relative',
          discount && 'tw-mt-3'
        )}
      >
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
          {/* <div className="tw-flex  tw-items-center tw-text-3xl tw-justify-between  "> */}
          <div className="tw-flex tw-flex-row tw-gap-3 tw-text-3xl  tw-items-center">
            <FontAwesomeIcon icon={UpgradeIcons[props.upgrade.title]} />
            <div className="tw-font-bold tw-leading-8">
              {t(props.upgrade.title)}
            </div>
          </div>
          {/* {!isNutaku &&           <div className="tw-flex tw-flex-row tw-gap-3">
      
              <div className="tw-font-bold tw-text-xl">
                ${props.isYearly ? props.upgrade.price_year :props.upgrade.price}
              </div>
           
             
          </div>} */}
          {/* </div> */}
        </div>
        <div className="tw-leading-none tw-w-full tw-border-b tw-border-white tw-border-opacity-20 tw-pb-3 tw-opacity-75">
          {'+'}
          {props.isYearly ? '365' : '30'} {t('days from today')}
        </div>
        <div className="tw-text-lg tw-leading-tight tw-opacity-75">
          {t(props.upgrade.description)}
        </div>
        {props.upgrade.title === 'GeneralUp' && (
          <div className="tw-grid tw-grid-cols-2 tw-gap-3">
            {props.upgrades.map(
              (upgrade) =>
                upgrade.title !== 'GeneralUp' && (
                  <div
                    className={cn(
                      'tw-flex tw-flex-row tw-gap-2 tw-items-center tw-leading-tight',
                      {
                        'tw-opacity-75': hasUpgrade(upgrade.title),
                      }
                    )}
                  >
                    <div className="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-shrink-0 tw-aspect-square">
                      <FontAwesomeIcon icon={UpgradeIcons[upgrade.title]} />
                    </div>
                    <div className={cn('tw-opacity-75')}>
                      {hasUpgrade(upgrade.title) && (
                        <span className="tw-font-bold">Active: </span>
                      )}
                      {t(upgrade.title)}
                    </div>
                  </div>
                )
            )}
          </div>
        )}

        {!isNutaku && canBuy && (
          <div className="tw-gap-3 tw-flex tw-items-center tw-justify-center tw-flex-wrap tw-z-20 ">
            {!priceButtonClicked ? (
              <PriceButtonFlow
                isYearly={!!props.isYearly}
                onClick={() => setPriceButtonClicked(true)}
                price={Number(props.upgrade.price)}
                priceYear={Number(props.upgrade.price_year)}
              />
            ) : !subscriptionProvider ? (
              subscriptionProvidersPreview
            ) : (
              subscriptionProviderElement(
                subscriptionProviderInteraction[subscriptionProvider](),
                subscriptionProvider!
              )
            )}
          </div>
        )}
        {canBuy && isNutaku && (
          <div className="tw-w-full tw-flex tw-flex-col tw-gap-3 tw-items-center tw-justify-center">
            <TemptationsButton
              color="red"
              size="base"
              onClick={() => {
                nutakuCreatePayment();
              }}
            >
              <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                <div>Month:</div>
                <img
                  src={getIconUrl(IconSrc.NutakuCoin)}
                  className="tw-w-6 tw-h-6 tw-shrink-0"
                />
                <div>
                  {props.isYearly
                    ? Math.floor(Number(props.upgrade.nutaku_price_year) / 12)
                    : props.upgrade.nutaku_price}
                </div>
              </div>
            </TemptationsButton>
            {props.isYearly && (
              <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-leading-tight tw-opacity-75">
                <div>{t('You will be charged')}</div>
                <img
                  src={getIconUrl(IconSrc.NutakuCoin)}
                  className="tw-w-6 tw-h-6 tw-shrink-0"
                />
                <div>{props.upgrade.nutaku_price_year}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
