import {
  createPaymentOptions,
  HornyNutakuSDK,
  PaymentEvent,
} from 'hornynutakusdk';

export type TokenResponse = {
  token: string;
  refresh_token: string;
};

export class Nutaku {
  static getUserId() {
    return HornyNutakuSDK.getNutakuUserId();
  }

  static createPayment(options: createPaymentOptions) {
    return HornyNutakuSDK.createPayment(options);
  }

  static addListener(
    eventName: string,
    listenerFunc: (info: PaymentEvent) => void
  ) {
    return HornyNutakuSDK.addListener(eventName, listenerFunc);
  }

  static logout() {
    return HornyNutakuSDK.nutakuLogout();
  }

  static async getAuthData() {
    const data = await HornyNutakuSDK.getAuthData();
    if (data.error || !data.response) {
      throw new Error(data.error ? data.error : 'No response from Nutaku');
    }
    const parsedResponse: TokenResponse = JSON.parse(data.response);
    return parsedResponse;
  }
}

export type nutakuCreatePaymentOptions = createPaymentOptions;

export type nutakuPaymentEvent = PaymentEvent;
